import React, { useState } from 'react'
import { Link } from 'gatsby'
import { graphql, useStaticQuery } from 'gatsby'
import Fuse from 'fuse.js'

import styled from 'styled-components';

const MatchingSearchResults = styled.div`
  background: #fff;
  border: 1px solid rgba(26,26,26,0.3);	
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  max-width: 600px;
  position: absolute;
  top: 46px;
  right: 0;
  max-width: 350px;
  width: 100%;
  z-index: 3;
`;

const LinkContent = styled(Link)`
  align-items: center;
  display: flex;
  flex-direction: row;
  min-height: 30px;
  text-decoration: none;
  width: 100%;
`;

const SearchBoxContainer = styled.div`
  margin-bottom: 32px;
  max-width: 350px;
  position: relative;
`;

const TextInput = styled.input`  
  background-color: white;
  border: 2px solid rgba(26,26,26,0.1);	
  border-radius: 24px;
  box-sizing: border-box;
  color: rgba(26,26,26,0.8);	
  font-size: 16px;
  font-family: Roboto, sans-serif;
  height: 42px;
  max-width: 350px;
  padding: 10px 15px 10px 15px;
  outline: none;
  width: 100%;
`;

const ClickButton = styled.div`
  display: flex;
  align-items: center;
  height: 15px;
  position: absolute;
  right: 10px;
  top: 14px;
  width: 15px;
`

const SearchTitle = styled.div`
  align-items: center;
  color: rgba(26,26,26,0.8);	
  display: flex;
  font-size:  14px;
  margin: 0 15px;
  text-align: left;
  width: 100%;
  &:hover {
    color: red; 
  }
`;

const processPathIntoString = value => {
    let parts = value.split('/')
    return parts[2] && parts[2].replace(/-/g, ' ');
}


const findResults = (data, searchValue) => {
    const removeDuplicates =  data.filter(i => !i.path.includes('blog-mobile'));
    let modifiedData = removeDuplicates.map(item => ({ ...item, string: processPathIntoString(item.path)}))
    const options = {
        shouldSort: true,
        threshold: 0.6,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: [{
            name: 'string',
            weight: 0.4
        }, {
            name: 'context.tags',
            weight: 0.6
        }]
    };

    const fuse = new Fuse(modifiedData, options); // "list" is the item array
    const result = fuse.search(searchValue);
    return result && result.slice(0, 6);
};

export const Search = () => {
  const { icon, pagesUrlsAndTags } = useStaticQuery(graphql`{
    icon: file(name: { eq: "search" }, ext: { eq: ".svg" }) {
      publicURL
    }
    pagesUrlsAndTags: allSitePage(filter: { path: { ne: "/404/" }}) {
      nodes {
        path
        context {
          tags
        }
      }
    }
  }`)

  const [searchValue, setSearchValue] = useState('');
  const foundResultsSearch = findResults(pagesUrlsAndTags.nodes, searchValue)
  const showResults = searchValue.trim().length > 2 && foundResultsSearch.length > 0

  return (
    <SearchBoxContainer>
      <TextInput
        type='text'
        name='search'
        placeholder='Search Blog'
        value={searchValue}
        onChange={e => setSearchValue(e.target.value)}
      />

      <ClickButton>
        <img src={icon && icon.publicURL}/>
      </ClickButton>

      { showResults && <MatchingSearchResults>
        { foundResultsSearch.map((fr, f) => {
          const { string, path } = fr;
          return (
            <LinkContent to={path} key={f}>
              <SearchTitle >{string}</SearchTitle>
            </LinkContent>
          );
        } ) }
      </MatchingSearchResults> }
    </SearchBoxContainer>
  );
}